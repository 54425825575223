import {Link, usePage} from '@inertiajs/react';
import RootLayout from '@/Layouts/RootLayout';
import {Icons} from '@/Components/Icons.js';
import {useTranslation} from 'react-i18next';
import {PropsWithChildren, useEffect} from 'react';


export default function Guest ({ children }: PropsWithChildren) {
    const { t } = useTranslation();
    const { url } = usePage();

    // Helper to automatically scroll to top
    useEffect(() => {
        window.scroll({ top: 0 });
    }, [url]);

    return (
        <RootLayout>
            <div className="min-h-screen flex flex-col items-center sm:pt-0">
                <Link href={route('home')} className="mt-12 mb-6">
                    <Icons.logo className="w-20 h-20"/>
                </Link>

                <div className="w-full sm:max-w-md px-8 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg">
                    {children}
                </div>
                {route().current() === 'register' && (
                    <div className="mt-6 flex w-full flex-col items-center text-sm">
						<span>
							{t('auth.alreadyHasUser')}
						</span>
                        <a href={route('login')} className="text-blue-400">
                            {t('buttons.login')}
                        </a>
                    </div>
                )}
                {route().current() === 'login' && (
                    <div className="mt-6 flex w-full flex-col items-center text-sm">
                        <span>
                            {t('auth.noUser')}
                        </span>
                        <a href={route('register')} className="text-blue-400">
                            {t('buttons.register')}
                        </a>
                    </div>
                )}
            </div>
        </RootLayout>
    );
}
